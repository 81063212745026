import React from 'react'
import ClientCard from 'components/client-card'
import PropTypes from 'prop-types'
import { window } from 'browser-monads'
import { woodwatch_1, woodwatch_2, yummygum_2, woodwatch_3, bibbr_1, bibbr_2, bibbr_3, avy_2 } from 'images/klanten'
import { avy_logo, bibbr_logo } from 'images/klanten'
import Layout from '../../layouts'
import Helmet from 'react-helmet'

const dummyKlantVerhaal = {
  pageTitle: '“We hebben vooral gekozen voor gemak”',
  pageStreamer: 'Bibbr te Rotterdam, Interview met Ruben Paijens',
  useCase: '75-100 werknemers',
  person: {
    name: 'Ruben Paijens',
    functionTitle: 'Mede-oprichter Bibbr',
    companyLogo: bibbr_logo,
    description: 'Een jong bedrijf dat studenten inzet voor grote marketing en salesprojecten.',
    portraitImage: bibbr_1,
    portraitImageSmall: bibbr_2,
    link: 'https://bibbr.nl',
    internallink: false,
    btnText: 'Bekijk website&nbsp;→'
  },
  content: [{
    title: 'Bedrijf',
    description: 'Zoek je als student een uitdagende bijbaan? Bel dan eens naar Rotterdam, want daar zit Bibbr. Dit jonge bedrijf zet studenten in voor grote marketing en salesprojecten. Dan moet je niet denken aan simpele flyer-acties in de binnenstad, maar het liefst wat complexer. Bijvoorbeeld merchandise acties, koude acquisitie  en zelfs kookdemo’s in supermarkten. Kortom, bij Bibbr houden ze wel van een uitdaging. We spraken met Ruben Paijens, mede-oprichter van Bibbr. Hij vertelt over zijn ervaringen met personeel en het gebruik van Employes.'
    }, {
      title: 'Personeel',
      description: 'Ruben: “We zijn in februari 2017 officieel begonnen met het aannemen van personeel en momenteel hebben we geregeld meer dan 100 mensen op de payroll.” Had je van te voren een idee hoe je je salarisadministratie wilde aanpakken? “Omdat je in onze branche al snel veel werknemers moet kunnen verlonen hebben we ons direct goed in verdiept. We zijn in gesprek gegaan met een 3 administratiekantoren om te kijken wat zij voor ons konden doen. Tegelijkertijd kwam ik in die periode ook een advertentie van jullie tegen en ben ik met jullie in contact gekomen. Het contact was goed en wij zagen veel voordelen waardoor we voor Employes gekozen hebben.'
    }, {
      image: bibbr_3
    }, {
      title: 'Waarom gekozen voor Employes?',
      description: 'Ruben: “In de opstart hebben we vooral echt gekozen voor gemak. Ik vind het vooral fijn dat jullie betrokken zijn. Dat als er vragen zijn, makkelijk te bereiken zijn. En jullie proberen alles altijd zo goed mogelijk uit te leggen.”'
    }, {
      title: 'Payroll',
      description: 'Is payroll nog een overweging geweest voor jullie? Ruben: “We hebben wel naar payroll gekeken. Maar dit vonden we te duur. Daarnaast is het gebruik van Employes zo gemakkelijk, daarvoor hoef je zeker niet naar een payroller. Het enige voordeel zou kunnen zijn dat wij niet aansprakelijk zijn voor ziekte. Maar als je onderaan de streep kijkt kan payroll gewoon niet uit. Dan is Employes gewoon veel goedkoper en gemakkelijker.”'
    }, {
      quote: '“Het gebruik van Employes is zo gemakkelijk, daarvoor hoef je zeker niet naar een payroller.”'
    }],
  otherClientStories: [{
    name: 'Vince Schwidder',
    functionTitle: 'Mede-oprichter, Yummygum',
    companyLogo: '',
    description: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: yummygum_2,
    quote: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    link: '/klanten/yummygum/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Ilse van Nierop',
    functionTitle: 'HR-manager, Avy',
    companyLogo: '',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: avy_2,
    quote: 'Ik kan onze salarisadministratie nu doen binnen 10 minuten',
    link: '/klanten/avy/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  },{
    name: 'Kevin van der Veer',
    functionTitle: 'Mede-oprichter, WoodWatch',
    description: 'Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel.',
    portraitImage: woodwatch_2,
    portraitImageSmall: woodwatch_2,
    quote: 'Payroll, dat kost me gewoon teveel.',
    internallink: true,
    link: '/klanten/woodwatch/',
    btnText: 'Lees ervaring&nbsp;→'
  }]
}


const klantverhaalTemplate = ({location}) => (
  <Layout location={location}>
    <main className="clientstory animated fadeInPage">

      <Helmet>
        <title>Interview met Ruben Paijens van Bibbr | Employes</title>
        <meta name="description" content="Ruben aan het woord over de salarisadministratie van Bibbr. Een jong bedrijf dat studenten inzet voor grote marketing en salesprojecten." />
        <meta itemprop="name" content="Interview met Ruben Paijens van Bibbr | Employes" />
        <meta itemprop="description" content="Ruben aan het woord over de salarisadministratie van Bibbr. Een jong bedrijf dat studenten inzet voor grote marketing en salesprojecten."/>
        <meta itemprop="image" content="https://employes.nl/static/meta-bibbr.jpg"/>

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://employes.nl/klanten/bibbr/"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Interview met Ruben Paijens van Bibbr | Employes"/>
        <meta property="og:description" content="Ruben aan het woord over de salarisadministratie van Bibbr. Een jong bedrijf dat studenten inzet voor grote marketing en salesprojecten."/>
        <meta property="og:image" content="https://employes.nl/static/meta-bibbr.jpg"/>

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Interview met Ruben Paijens van Bibbr | Employes"/>
        <meta name="twitter:description" content="Ruben aan het woord over de salarisadministratie van Bibbr. Een jong bedrijf dat studenten inzet voor grote marketing en salesprojecten."/>
        <meta name="twitter:image" content="https://employes.nl/static/meta-bibbr.jpg"/>

      </Helmet>


      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid between align-middle">
            <div className="col-6 no-pad">
              <h2>{ dummyKlantVerhaal.pageTitle }</h2>
              <p className="streamer large margin-l-bottom">{ dummyKlantVerhaal.pageStreamer }</p>
              <span className="badge green">Bedrijfsgrootte: <b>{ dummyKlantVerhaal.useCase }</b></span>
            </div>
            <ClientCard className="col-6 no-pad no-logo" person={dummyKlantVerhaal.person} isSmall={(window.innerWidth < 768)}/>
          </div>
        </div>
      </header>
      <section className="up">
        <div className="clientstory-content padding-xl">
            {dummyKlantVerhaal.content.map((section, idx) => {
              const firstObjectKey = Object.keys(section)[0]
              const isImage = (firstObjectKey === 'image')
              const { title, description } = section

              return (
                <div className="paragraph margin-m-bottom" key={idx}>
                  <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                    <div className="grid yg center">
                      <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                      {{
                        title: (
                          <div className="text">
                            <h4>{ title }</h4>
                            <p className="large">{ description}</p>
                          </div>
                        ),
                        quote: (
                          <div className="client-quote">
                            “Het gebruik van Employes is zo gemakkelijk, daarvoor hoef je zeker niet naar een payroller.”
                          </div>
                        ),
                        image: (
                          <img src={section.image} alt="klant van Employes"/>
                        )
                      }[firstObjectKey]}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="clientstory-others padding-xl">
          <div className="container-md">
            <div className="grid yg center text-center margin-l-bottom">
              <div className="col-12">
                <h6 className="eyebrow">Ervaringen</h6>
                <h2 className="no-margin">Andere klantverhalen</h2>
              </div>
            </div>
            <div className="grid yg">
              {dummyKlantVerhaal.otherClientStories.map((otherclient, idx) => {
                const { name, functionTitle, companyLogo, portraitImage, quote } = otherclient

                return (
                  <div className="col-4" key={otherclient.name} key={idx}>
                    <ClientCard person={otherclient} isSmall={true}/>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </section>
    </main>
  </Layout>
)

export default klantverhaalTemplate
